<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {paginationHelper} from "@/helpers/pagination-helper";
import DeleteParserModal from "@/components/parser/delete-parser-modal.vue";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import {debounce} from "vue2-leaflet/dist/vue2-leaflet.min";
import {dateUtil} from "@/helpers/date-util";
import {warehouseStatusHelper} from "@/helpers/warehouse-status-helper";
import ParserTagXmlInfo from "@/components/parser-tag-xml-info.vue";
import confirmModal from "@/components/confirm-modal.vue";
import Swal from "sweetalert2";

/**
 * Parsers Component
 */
export default {

  components: {
    confirmModal,
    ParserTagXmlInfo,
    CustomCardSubtitle,
    DeleteParserModal,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,

        items: []
      },

      parserStatuses: [
        "ALL_STATUSES",
        "NOT_PUBLISHED",
        "XML_FILE_WITHOUT_CHANGE_FOR_48_HOURS",
        "NO_ERRORS",
        "ERROR"
      ],

      filtering: {
        warehouseStatusId: "",
        parserStatus: null
      }
    };
  },

  watch: {
    "table.currentPage": {
      deep: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          await this.loadParsers()
        }
      }
    },

    "table.perPage": {
      deep: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          await this.loadParsers()
        }
      }
    }
  },

  computed: {
    warehouseStatusHelper() {
      return warehouseStatusHelper
    },

    dateUtil() {
      return dateUtil
    },

    paginationHelper() {
      return paginationHelper
    }
  },

  async created() {
    await this.loadParsers()
  },

  methods: {
    handleSearch: debounce(function() {
      this.setToFirstPageAndRefresh()
    }, 250),

    async changeFilteringWarehouseStatus(statusId) {
      this.filtering.warehouseStatusId = statusId
      await this.loadParsers()
    },

    async changeFilteringParserStatus(parserStatus) {
      this.filtering.parserStatus = parserStatus !== "ALL_STATUSES" ? parserStatus : null
      await this.loadParsers()
    },

    async setToFirstPageAndRefresh() {
      this.table.currentPage = 1
      await this.loadParsers()
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('parsers.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "warehouseName", label: this.$t('parsers.table.warehouse')},
        {key: "xmlUrls", slot: true, label: "Linki XML"},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadParsers() {
      this.table.isBusy = true
      this.table.items = []
      this.table.count = 0

      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {
          data: {
            parsers,
            count
          }
        } = await axios.get(`/parser/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter,
            ...this.filtering
          }
        });

        const values = parsers
        for (const parser of values) {
          parser.parsers = new Map(parser.parsers.map((obj) => [obj.xmlUrl, obj]))

          if (parser.parserTags.length > 0) {
            for (const parserTag of parser.parserTags) {
              parserTag.tagNames = JSON.parse(parserTag.tagNames)
              parserTag.tagSetup = JSON.parse(parserTag.tagSetup)
            }
          }
        }

        this.table.items = values
        this.table.totalRows = count;
        this.table.rows = count
      } catch (error) {
        errorCatcher.catchErrors(error)
        this.table.items = []
      }

      this.table.isBusy = false
      return this.table.items
    },

    getParser(parserObject, xmlUrl) {
      if (!parserObject.parsers) {
        return null
      }

      return parserObject.parsers.get(xmlUrl)
    },

    getParserLastXmlChangesDetected(parser, xmlUrl) {
      if (!parser.parsers) {
        return null
      }

      const parserFromMap = parser.parsers.get(xmlUrl)
      if (!parserFromMap) {
        return null
      }

      return parserFromMap.lastXmlChangeDetectedAt || null
    },

    getParserStatus(parserObject, xmlUrl) {
      const parser = this.getParser(parserObject, xmlUrl)
      if (!parser) {
        return "NOT_PUBLISHED"
      }

      const lastXmlChangeDetectedAt = new Date(parser.lastXmlChangeDetectedAt)
      const futureDate = new Date(lastXmlChangeDetectedAt.getTime() + (48 * 60 * 60 * 1000));
      const errorMessageExists = parser.errorMessage && parser.errorMessage !== "null"

      if (parser.lastXmlChangeDetectedAt && futureDate <= new Date()) {
        return "XML_FILE_WITHOUT_CHANGE_FOR_48_HOURS"
      } else if (new Date(parser.refreshedAt) > new Date(parser.refreshAttemptAt) && !errorMessageExists) {
        return "NO_ERRORS"
      } else if (errorMessageExists && new Date(parser.lastErrorAt) > new Date(parser.refreshedAt)) {
        return "ERROR"
      } else if (!parser.lastXmlChangeDetectedAt) {
        return "WAITING_FOR_REFRESH"
      } else if (new Date(parser.refreshedAt) < new Date(parser.refreshAttemptAt) && !errorMessageExists) {
        return "WAITING_FOR_REFRESH_COMPLETE"
      }

      return "UNKNOWN_ERROR"
    },

    getParserStatusColor(parserStatus) {
      if (parserStatus === "ALL_STATUSES") {
        return ""
      }

      switch (parserStatus) {
        case "NOT_PUBLISHED":
          return "badge badge-soft-warning"
        case "XML_FILE_WITHOUT_CHANGE_FOR_48_HOURS":
          return "badge badge-soft-primary"
        case "NO_ERRORS":
          return "badge badge-soft-success"
        case "WAITING_FOR_REFRESH":
          return "badge badge-custom-purple"
        case "WAITING_FOR_REFRESH_COMPLETE":
          return "badge badge-custom-sea"
        case "ERROR":
          return "badge badge-soft-danger"
      }

      return "badge badge-soft-secondary"
    },

    getParserStatusName(parserStatus) {
      if (parserStatus === "ALL_STATUSES") {
        return "Wszystkie statusy"
      }

      return this.$t(`parser.status.${parserStatus}`)
    },

    async forceUpdate(parser) {
      const json = {
        warehouseId: parser.warehouseId
      }

      try {
        await axios.post(`/parser/force-update`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.$refs.confirmModal.hideModal()
        await Swal.fire("Sukces!", `Wymuszono aktualizacje parsera dla Hurtowni ${parser.warehouseName}`, "success")
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('parsers.title')"/>
    <delete-parser-modal ref="deleteParserModal" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">

            <div class="row mb-4">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="btn btn-success mb-2 px-4" href="/dashboard/admin/parser/create-or-edit">
                  <i class="mdi mdi-plus"></i> {{ $t('parsers.create.button') }}
                </a>
              </div>
            </div>

            <div class="row mb-4">
              <custom-card-subtitle title="Filtrowanie" />

              <div class="col-12 col-lg-2">
                <ecat-multiselect
                    @change="value => changeFilteringWarehouseStatus(value)"
                    save-id="id"
                    view-id="name"
                    label="Status Hurtowni"
                    placeholder="Wszystkie statusy"
                    reset-option-name="Wszystkie statusy"
                    fetch-one-url="/warehouse/status"
                    load-url="/warehouse/status/list/pagination"
                    query-url="/warehouse/status/by-name"
                    list-name="warehouseStatuses"
                    param="name"
                    :can-unselect="true"
                />
              </div>

              <div class="col-12 col-lg-2">
                <label>Status parsera</label>
                <vue-multiselect
                    v-model="filtering.parserStatus"
                    @select="value => changeFilteringParserStatus(value)"
                    :options="parserStatuses"
                    placeholder="Wszystkie statusy"
                    :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span :class="getParserStatusColor(option)">
                      {{ getParserStatusName(option) }}
                    </span>
                  </template>

                  <template slot="option" slot-scope="{ option }">
                    <span :class="getParserStatusColor(option)">
                      {{ getParserStatusName(option) }}
                    </span>
                  </template>
                </vue-multiselect>
              </div>
            </div>

            <div class="py-2">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <b-form-select
                          v-model="table.perPage"
                          size="sm"
                          :options="table.pageOptions"
                          @loaded="setToFirstPageAndRefresh"
                      />
                      &nbsp;{{
                        $t('table.entries')
                      }}
                    </label>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input
                          v-model="table.filter"
                          class="form-control form-control-sm ml-2"
                          type="search"
                          @keyup.enter="setToFirstPageAndRefresh"
                          @input="handleSearch"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm-12 col-sm-6" />

                <div class="col-sm-12 col-sm-6 d-inline-flex justify-content-end">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"/>
                    </ul>
                  </div>
                  <div class="align-items-center">
                    <label class="d-inline-flex align-items-center mx-2 mb-0">
                      <b-form-input v-model.number="table.inputPage" type="number" class="form-control form-control-sm ml-2 form-xs"/>
                    </label>
                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">{{ $t('table.go-to-page') }}
                    </b-button>
                  </div>
                </div>
              </div>

              <div class="table-responsive my-3">
                <template v-if="!table.isBusy">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th>Hurtownia</th>
                      <th style="width: 20%">Link XML</th>
                      <th style="width: 10%">Ostatnia zmiana w XML</th>
                      <th style="width: 5%">Status</th>
                      <th style="width: 5%">Status Hurtowni</th>
                      <th style="width: 25%">Tagi XML</th>
                      <th style="width: 5%">Narzut</th>
<!--                      <th style="width: 5%">Ilość produktów</th>-->
                      <th style="width: 15%">Akcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(parser, index) in table.items">
                      <tr :key="`parser-${index}`">
                        <td :rowspan="parser.parserTags.length">{{  parser.warehouseName }}</td>

                        <td v-if="parser.parserTags[0]"><a class="ecat-text-break" :href="`/dashboard/admin/parser/create-or-edit/${parser.warehouseId}`">{{ parser.parserTags[0].xmlUrl }}</a></td>
                        <td v-if="parser.parserTags[0]">{{  getParserLastXmlChangesDetected(parser, parser.parserTags[0].xmlUrl) ? dateUtil.asDateTimeNormal(getParserLastXmlChangesDetected(parser, parser.parserTags[0].xmlUrl)) : "Brak danych" }}</td>
                        <td v-if="parser.parserTags[0]"><span :class="getParserStatusColor(getParserStatus(parser, parser.parserTags[0].xmlUrl))">{{  $t('parser.status.' + getParserStatus(parser, parser.parserTags[0].xmlUrl)) }}</span></td>
                        <td :rowspan="parser.parserTags.length">
                          <span class="badge badge-soft-info" :style="warehouseStatusHelper.getStyle({ frontendHexColor: parser.warehouseStatusFrontendHexColor })">{{ parser.warehouseStatusName }}</span>
                        </td>
                        <td v-if="parser.parserTags[0]">
                          <parser-tag-xml-info :parser-tag="parser.parserTags[0]" />
                        </td>
                        <td :rowspan="parser.parserTags.length">{{ parser.warehouseProfitMargin }}%</td>
<!--                        <td :rowspan="parser.parserTags.length">TODO</td>-->
                        <td :rowspan="parser.parserTags.length">
                          <div class="button-items">
                            <a :href="`/dashboard/admin/parser/create-or-edit/${parser.warehouseId}`" class="btn btn-primary btn-sm">Edytuj</a>
                            <b-button @click="$refs.confirmModal.openModal(`Wymuś aktualizacje parsera dla hurtowni ${parser.warehouseName}`, '', 'Potwierdź', () => forceUpdate(parser))" variant="warning" class="btn-sm">Wymuś Aktualizacje</b-button>
<!--                            <b-button variant="outline-danger" class="btn-sm">Wyłącz</b-button>-->
                            <b-button @click="$refs.deleteParserModal.openModal(parser.warehouseId)" variant="danger" class="btn-sm">Usuń</b-button>
                          </div>
                        </td>
                      </tr>
                      <tr v-for="(parserTag, parserTagIndex) in parser.parserTags.slice(1)" :key="`parser-${index}-xml-${parserTagIndex}`">
                        <td><a class="ecat-text-break" :href="`/dashboard/admin/parser/create-or-edit/${parser.warehouseId}`">{{ parserTag.xmlUrl }}</a></td>
                        <td>{{  getParserLastXmlChangesDetected(parser, parserTag.xmlUrl) ? dateUtil.asDateTimeNormal(getParserLastXmlChangesDetected(parser, parserTag.xmlUrl)) : "Brak danych" }}</td>
                        <td><span :class="getParserStatusColor(getParserStatus(parser, parserTag.xmlUrl))">{{  $t('parser.status.' + getParserStatus(parser, parserTag.xmlUrl)) }}</span></td>
                        <td>
                          <parser-tag-xml-info :parser-tag="parser.parserTags[0]" />
                        </td>
                      </tr>
                    </template>
                    </tbody>
            </table>
                </template>
                <template v-else>
                  <div class="text-center py-3">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                  </div>
                </template>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    {{
                      $t('table.entries-footer', {
                        'amount': paginationHelper.getElementsAmount(table),
                        'elements': paginationHelper.getElementsCount(table),
                        'all': table.totalRows
                      })
                    }}
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          v-model="table.currentPage"
                          :total-rows="table.rows"
                          :per-page="table.perPage"
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>

<!--            <ecat-table-->
<!--                :table="table"-->
<!--                :fields="getFields()"-->
<!--                :items="loadParsers"-->
<!--                :pagination-top="true"-->
<!--            >-->
<!--              <template v-slot:xmlUrls="{ item }">-->
<!--                <a v-for="(object, index) in item.objects" :key="index" :href="object.xmlUrl">-->
<!--                  {{ object.xmlUrl }}-->
<!--                  <hr v-if="item.objects.length - 1 !== index" class="hr-line" />-->
<!--                </a>-->
<!--              </template>-->

<!--              <template v-slot:action="{ item }">-->
<!--                <div class="button-items">-->
<!--                  <a :href="`/dashboard/admin/parser/create-or-edit/${item.objects[0].warehouseId}`" class="btn btn-primary btn-sm">Edytuj</a>-->
<!--                  <b-button variant="danger" class="btn-sm" @click="$refs.deleteParserModal.openModal(item.objects[0].warehouseId)">Usuń</b-button>-->
<!--                </div>-->
<!--              </template>-->
<!--            </ecat-table>-->
          </div>
        </div>
      </div>
    </div>

    <confirm-modal ref="confirmModal" />
  </Layout>
</template>